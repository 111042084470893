@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Mono&display=swap);
body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.App {
  text-align: center; }

.App-header {
  background-color: #1E2749;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-logo {
  pointer-events: none;
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 94px;
  letter-spacing: 0.5em;
  text-transform: uppercase;
  margin-right: -0.5em;
  margin-bottom: .5em;
  color: #E8F1F2; }

.App-tagline {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.5em;
  margin-right: -0.5em;
  margin-bottom: .5em;
  color: #E8F1F2; }

.App-link {
  margin-top: 30px;
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.1em;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

