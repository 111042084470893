@import "sass/variables.scss";
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono&display=swap');

.App {
  text-align: center;
}

.App-header {
  background-color: $primaryColor;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-logo {
  pointer-events: none;
  
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 94px;
  letter-spacing: 0.5em;
  text-transform: uppercase;

  margin-right: -0.5em;
  margin-bottom: .5em;  

  color: $secondaryColor;
}

.App-tagline {
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.5em;

  margin-right: -0.5em; 
  margin-bottom: .5em; 

  color: $secondaryColor;
}

.App-link {
  margin-top: 30px; 
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.1em;
  text-decoration-line: underline;

  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
